import {useNavigate} from "react-router-dom";
import {handleGoToRegistration} from "../../utils/navigation-utils";
import arrowRight from "../../assets/arrow-right.svg";
import wallet from "../../assets/wallet.png";

export const HomeMain = () => {
    return (
        <section className="container">
            <div className="home-info">
                <h1>Finstee is your own financial ecosystem.</h1>
                <p>Control the financial assets, receive real-time info in dashboards and achieve your personal and business goals.</p>
                <button className="color l" onClick={handleGoToRegistration}>
                    <span>Get started</span>
                    <img src={arrowRight} alt="arrow-right"/>
                </button>
            </div>
            <img src={wallet} alt="wallet"/>
        </section>
    )
}