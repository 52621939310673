import {useNavigate} from "react-router-dom";
import {handleGoToRegistration} from "../../utils/navigation-utils";
import profile from '../../assets/profile.svg';
import registration from '../../assets/registration.svg';
import verifyOnline from '../../assets/verify_online.svg';
import pay from '../../assets/pay.svg';

export const Apply = () => {
    return (
        <section className="container">
            <div className="apply">
                <div className="apply-info">
                    <h2>Apply now</h2>
                    <p>Get an active account in a few minutes from anywhere in the world</p>
                    <button className="color l" onClick={handleGoToRegistration}>Get started now</button>
                </div>
                <div className="apply-structure">
                    <div className="apply-segment">
                        <div className="apply-segment-image">
                            <img src={profile} alt="profile"/>
                            <div className="separator"/>
                        </div>
                        <div className="apply-segment-text">
                            <h3 className="apply-segment-title">Choose profile type</h3>
                            <p className="apply-segment-description">You can register a private or business account</p>
                        </div>
                    </div>
                    <div className="apply-segment">
                        <div className="apply-segment-image">
                            <img src={registration} alt="registration"/>
                            <div className="separator"/>
                        </div>
                        <div className="apply-segment-text">
                            <h3 className="apply-segment-title">Complete registration</h3>
                            <p className="apply-segment-description">Fiil in the information: it takes less than 10 minutes</p>
                        </div>
                    </div>
                    <div className="apply-segment">
                        <div className="apply-segment-image">
                            <img src={verifyOnline} alt="verify_online"/>
                            <div className="separator"/>
                        </div>
                        <div className="apply-segment-text">
                            <h3 className="apply-segment-title">Verify Identity Online</h3>
                            <p className="apply-segment-description">We provide fully remote identification within two days</p>
                        </div>
                    </div>
                    <div className="apply-segment">
                        <div className="apply-segment-image">
                            <img src={pay} alt="pay"/>
                        </div>
                        <div className="apply-segment-text">
                            <h3 className="apply-segment-title">Pay with Finstee</h3>
                            <p className="apply-segment-description">After verification you can use all features of the account</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
