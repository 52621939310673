import {Link, useNavigate} from "react-router-dom";
import logo from "../assets/logo.svg";
import facebookIcon from "../assets/fаcebook.svg";
import instagramIcon from "../assets/instagram.svg";
import linkedinIcon from "../assets/linkedIn.svg";

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer>
            <div className="container">
                <div className="navigation">
                    <img className='logo' src={logo} alt="logo" onClick={() => navigate('/')}/>
                    <nav>
                        <Link to={'/'}>Home</Link>
                        <Link to={'/about-us'}>About us</Link>
                        <Link to={'/our-services'}>Our Services</Link>
                        <Link to={'/contact-us'}>Contact us</Link>
                    </nav>
                    <div className='social-links'>
                        <a href="https://www.facebook.com/finsteee"><img src={facebookIcon} alt="fafcebook-link"/></a>
                        <a href="https://www.instagram.com/fin.steee/"><img src={instagramIcon} alt="instagram-link"/></a>
                        <a href="https://www.linkedin.com/company/finstee/"><img src={linkedinIcon} alt="linkedin-link"/></a>
                    </div>
                </div>
                <div className="additional-navigatoin">
                    <span>2023 Relume. All right reserved.</span>
                    <nav>
                        <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
                        <Link to={'/legal-notice'}>Legal Notice</Link>
                        <Link to={'/risk-appetite-policy'}>Risk Appetite Policy</Link>
                        <Link to={'/privacy-policy'}>Privacy Policy</Link>
                        <Link to={'/cookies-policy'}>Cookies Policy</Link>
                    </nav>
                </div>
            </div>
        </footer>
    )
}

