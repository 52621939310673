import {ReasonsList} from "../../components/reasons-list";

export const Why = () => {
    return (
        <section className="container">
            <div className="why">
                <h2>Why Finstee?</h2>
                <ReasonsList withBg/>
            </div>
        </section>
    )
}