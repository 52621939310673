import money from '../../assets/money.svg';
import users from '../../assets/users.svg';
import card from '../../assets/card.svg';

export const Services = () => {
    return (
        <section className="container">
            <div className="services">
                <div className="services-info">
                    <h2>Our services</h2>
                    <p>Based in Canada, our team works hard to develop financial services for local and international businesses. Finstee is licenced for foreign exchange dealing, money transferring and payment service providing. We are focused on creating easy-to-use banking solutions!</p>
                </div>
                <div className="container services-list">
                    <div>
                        <img src={money} alt="money"/>
                        <h3>Money transferring and currency exchange</h3>
                        <p>Make international payments in various currencies quickly and securely. We support SWIFT and SEPA payments. With Finstee, you can also exchange currencies online at the best rates.</p>
                    </div>
                    <div>
                        <img src={users} alt="users"/>
                        <h3>Individual and business accounts</h3>
                        <p>Each Finstee client receives an individual IBAN. It guarantees fast and secure domestic and international payments, allows bulk payments, online currency conversion, and more.</p>
                    </div>
                    <div>
                        <img src={card} alt="card"/>
                        <h3>Payment Processing</h3>
                        <p>Finstee payment processing solution can be used on your website, in a mobile app, online store or another platform. You can receive online payments from customers fast and efficiently.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}