import lamp from "../../assets/lamp.png";
import magnifyingGlass from "../../assets/magnifying_glass.png";

export const Goals = () => (
    <section className='goals'>
        <div className="goal">
            <div className="gial-preview">
                <img src={lamp} alt="lamp"/>
            </div>
            <div className="goal-text">
                <h2>Our Mission</h2>
                <p>We develop modern, easy-to-use financial services to help clients manage their financial assets efficiently. Finstee works with a network of reliable payment institutions, providing a flexible system of multi-currency accounts and related services.</p>
            </div>
        </div>
        <div className="goal">
            <div className="gial-preview">
                <img src={magnifyingGlass} alt="magnifying_glass"/>
            </div>
            <div className="goal-text">
                <h2>Our Vision</h2>
                <p>Finstee shall become an even more multifunctional payment service provider, offering customers a comfortable working environment based on customer requests, the latest IT achievements and the diversification of money use.</p>
            </div>
        </div>
    </section>
)