import {Page} from "../components/page";
import {HomeMain} from "../sections/home-sections/home-main";
import {Services} from "../sections/home-sections/services";
import {Why} from "../sections/home-sections/why";
import {Apply} from "../sections/home-sections/apply";

export const Home = () => (
    <Page>
        <HomeMain />
        <Services />
        <Why />
        <Apply />
    </Page>
)
