import {Header} from "./header";
import {Footer} from "./footer";

export const Page = ({ children }) => (
    <>
        <Header />
        <main>
            {children}
        </main>
        <Footer/>
    </>
)
