import {Page} from "../components/page";

export const PrivacyPolicy = () => {
  return (
      <Page>
          <div className="policy-container">
              <h1>Privacy Policy</h1>
              <p>
               The information in this document is confidential to the person to whom it is addressed and
               should not be disclosed to any other person. It may not be reproduced in whole, or in part, nor
               may any of the information contained therein be disclosed without the prior consent of the
               directors of KURTLEY QURESHI CONSULTANTS LTD (‘the Company’).
               A recipient may not solicit, directly or indirectly (whether through an agent or otherwise) the
               participation of another institution or person without the prior approval of the directors of the
               Company.
              </p>
              <p>
               The contents of this document have not been independently verified and they do not purport to
               be comprehensive, or to contain all the information that a prospective investor may need.
               No representation, warranty or undertaking, expressed or implied is or will be made or given
               and no responsibility or liability is or will be accepted by the Company or by any of its
               directors, employees or advisors in relation to the accuracy or completeness of this document or
               any other written or oral information made available in connection with the Company.
               Any form of reproduction, dissemination, copying, disclosure, modification, distribution and or publication of this material is strictly prohibited.
              </p>

              <h2>Privacy Policy</h2>
              <p>
               KURTLEY   QURESHI   CONSULTANTS   Limited   (“KURTLEY   QURESHI
               CONSULTANTS”) is a company incorporated in Canada with company number BC1395192
               and   whose   registered   office   is   at  701 WEST GEORGIA STREET, SUITE 1500
               VANCOUVER, BC, CANADA V7Y1C6.   KURTLEY   QURESHI   CONSULTANTS   is
               authorized by the Financial Transactions and Reports Analysis Centre (FINTRAC) as an
               Money   Service   Business   (MSB)   with   License   No.   M23111500   (https://fintrac-
               canafe.canada.ca/msb-esm/msb-eng) under the Proceeds of Crime (Money Laundering) and
               Terrorist Financing Act (PCMLTFA).
              </p>
              <h2>Overview</h2>
              <p>
               KURTLEY QURESHI CONSULTANTS has developed this Privacy Policy (“Policy”) in
               compliance with the Personal Information Protection and Electronic Documents Act (PIPEDA)
               <a target='_blank' rel="noreferrer" href="https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/">https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/</a>,
               store, share and transfer your personal data through our services and via our online presence,
               which includes the website www.northpeakpay.com ("our website").
               We are committed to protecting and respecting your privacy. This Policy provides information
               on how we use personal data, with whom we share it, your rights and choices, and how you can
               contact us about our privacy practices. This policy does not apply to any third-party websites,
               their products or services, even if they link to our services or site.
               This Policy (together with our Terms and Conditions and Cookie Policy) constitutes the basis
               on how any personal data collected by us will be processed. Please read the following carefully
               to understand our views and practices regarding your personal data. By using our services or
               visiting our website you are accepting and consenting to the practices described below.
              </p>

              <h2>Information provided by you</h2>
              <p>
                You may provide us with information about you in various ways - filling in electronic forms on
               our website or contacting us via phone (including the mobile app), e-mail or otherwise. This
               includes any information provided by you when submitting application to open an account,
               subscribing to our service, searching for a product or service on our website or reporting on a
               problem on our site. The information you provide us shall include:
              </p>
              <p>
               A. For a private person (individual applicant/client):
               <ul>
                 <li>Your name;</li>
                 <li>Date of birth;</li>
                 <li>Physical appearance (photo/video);</li>
                 <li>Address of residence with confirmation (utility bill or bank reference);</li>
                 <li>Passport/ID details (including image);</li>
                 <li>Job position/employer;</li>
                 <li>Contact details (e-mail address, phone number).</li>
               </ul>
              </p>
              <p>
                 B. For corporate (legal entity): For shareholders (with over 10% control or ownership),
                directors and officers authorised to operate the account on behalf of this corporate client
                (further “signers”) we ask to provide the information listed in section A above, and in addition
                the following:
                <ul>
                 <li>CV of each shareholder, director and signer;</li>
                 <li>Description of your business and partners;</li>
                 <li>Full set of corporate documents (certificates, licenses, resolutions) required for identification
                 of applicant and its representatives;</li>
                 <li>Contact details (e-mail address, phone number, website). We reserve our right to request the
                 financial statements of the applicant.</li>
                </ul>
              </p>
              <p>
                By submitting any application, you confirm that you are authorised and have the necessary
               consents to disclose the personal data provided in the application to us. We shall not be liable
               for any claim brought by any individual arising from an act or omission resulting from your
               failure to ensure that the lawful transfer of personal data.
              </p>

              <h2>Information we collect about you</h2>
              <p>
                  Every time you visit our website, we may automatically collect the following:
                  <ul>
                      <li>
                          The Internet Protocol (IP) address used to connect your device to the Internet, your login
                          information, browser type and version, browser plug-in types and versions, operating system
                          and platform and other technical data;
                      </li>
                      <li>
                              Information about your visit like Uniform Resource Locators (URL) clickstream to, through
                          and from our site; products, services or other information viewed or searched for; page
                          response, interaction statistics and logs, including errors, and methods used to browse away
                          from the page and any phone number used to call us. We also may collect information about
                          online activity on our website and connected devices over time and across third-party websites,
                          devices, apps and other features and services.
                      </li>
                  </ul>
              </p>
              <p>
                  Cookies Our website uses cookies to distinguish visitors of our website. This helps us to
                  provide you with a good and quality service when you browse our website and allows us to
                  improve our website. For more detailed information on cookies, please refer to our Cookie
                  Policy.<br/>
                  Use of the received and collected information We may use the information about you in the
                  following ways: Information you submit to us<br/>
                  We may use this information:
                  <ul>
                      <li>
                          To evaluate any applications to receive our services, provide you with the services as stated in
                          contracts between you and us and to provide you with the information, products and services
                          that you request from us;
                      </li>
                      <li>
                          To enable fraud protection and risk management processes such as checking third party
                          sanctions lists and other databases to ensure that you are eligible to receive our services;
                      </li>
                      <li>
                          To monitor, prevent and detect unauthorised payment transactions;
                      </li>
                      <li>
                          To comply with our
                          legal and compliance obligations;
                      </li>
                      <li>
                          To respond to enquiries, send service notices and provide customer support;
                      </li>
                      <li>
                          To provide you with information about other products and services we offer that are similar
                          to those that you have already purchased or enquired about;
                      </li>
                      <li>
                          To provide you with information about other products or services that may interest you;
                      </li>
                      <li>
                          To inform you about any changes to our services; and
                      </li>
                      <li>
                          To ensure that the content of our website is presented in the most convenient manner for you.
                          Information we collect about you We will use this information:
                      </li>
                      <li>
                          To administer our website as well as for internal operations (troubleshooting, testing,
                          research, data analysis, a.o.);
                      </li>
                      <li>
                          To improve the content on our website and make sure it is presented in the most convenient
                          manner;
                      </li>
                      <li>
                          To keep the website safe and secure;
                      </li>
                      <li>
                          To measure or understand the effectiveness of advertising we serve to you and others, and to
                        deliver relevant advertising to you;
                      </li>
                      <li>
                          To make suggestions and recommendations to visitors of our website about products or
                          services that may be of interest. Disclosure of your information Your personal data may be
                          shared with any member of our Group, which means affiliated companies, subsidiaries, owners
                          and their subsidiaries, as defined in PIPEDA. We may also share your personal data with third
                          parties including:
                      </li>
                      <li>
                          Business partners including subcontractors for the performance of any contract we enter into
                        with them or you;
                      </li>
                      <li>
                          Third party providers and/or outsourced agencies in order to fulfil necessary functions set by
                        regulations and business partners;
                      </li>
                      <li>
                          Analytics and search engine providers that assist us in the improvement and optimisation of
                          our site.
                          We may disclose your personal information to third parties:
                      </li>
                      <li>
                          If we are under a duty to disclose or share your personal data in order to comply with any
                          legal obligation, or in order to enforce or apply our Terms and Conditions and/or other
                          agreements;   to   protect   the   rights,   property,   or   safety   of   KURTLEY   QURESHI
                          CONSULTANTS,   our   clients,   business   partners,   or   others.   This   includes   exchanging
                          information with other companies and organisations for the purposes of fraud protection and
                          credit risk reduction;
                      </li>
                      <li>
                          To respond to requests from courts, law enforcement, regulatory agencies, and other public
                        and government authorities, which may include authorities outside your country of residence;
                      </li>
                      <li>
                          In the event sale of any business or assets by our Company, in which case we may disclose
                        your personal data to the prospective buyer of such business or assets;
                      </li>
                      <li>
                          If KURTLEY QURESHI CONSULTANTS or substantially all of its assets are acquired by a
                          third party, in which case personal data held by it about its customers will be one of the
                          transferred assets.
                      </li>
                  </ul>
              </p>

              <h2>Storage of data</h2>
              <p>
                  KURTLEY QURESHI CONSULTANTS maintains various security measures designed to
                  provide reasonable protection for your personal data against loss, misuse, unauthorised access,
                  disclosure, and alteration. The security measures include firewalls, data encryption, information
                  access authorisation and physical access controls to our data centres. Your personal data is
                  accessible to a limited number of personnel to perform their duties.
                  We are dedicated to securing our systems and services and are constantly improving them, you
                  are   responsible   for   securing   and   maintaining   the   privacy   of   your   password(s)   and
                  account/profile information and verifying that any personal data we maintain about you is
                  accurate and up to date. We ask you not to share a password with anyone. We are not
                  responsible for protecting any personal data that is with a third-party based on an account
                  connection that you have authorised. This If you have reason to believe that that the security of
                  your account has been compromised, please contact us immediately.
                  We cannot guarantee the security of your data transmitted to our site; any transmission is at
                  your own risk. Once we have received your information, we will use strict procedures and
                  security features for unauthorised access prevention.
                  We retain your personal data as long as we are providing our services to you and retain personal
                  data after we cease providing services to you, even if you close your account, to the extent
                  necessary to comply with our legal and regulatory obligations, including tax, accounting, and
                  financial reporting, fraud monitoring, detection and prevention, we are required to retain the
                  data by our contractual commitments, and where data retention is mandated by the payment
                  methods that we support.
              </p>
              <p>
                  <h3>Your rights</h3>
                  You may limit or restrict our use and disclosure of your personal data and we will try to comply
                  with your request as soon as reasonably practicable.
              </p>
              <p>
                  <h3>Opting out of electronic communications</h3>
                  In case you no longer want to receive marketing-related emails from us, you may opt-out via
                  the unsubscribe link included in such emails. Please note that while you may opt-out of
                  receiving marketing-related emails from us, we may still send you important messages or
                  notifications that are required to provide you with our services and for KURTLEY QURESHI
                  CONSULTANTS to comply with laws and regulations.
              </p>
              <p>
                  <h3>Updating your account personal data</h3>
                  We encourage you to review, correct, or update personal data that you have previously
                  disclosed to us in order to keep it up to date, you may do so by signing in to your KURTLEY
                  QURESHI CONSULTANTS account or by contacting us.
              </p>
              <p>
                  <h3>Data protection rights</h3>
                  Depending on your location and subject to laws and regulations, you may have the following
                  rights with regard to the personal data we control about you:
                  <ul>
                      <li>
                          The right to request confirmation of whether KURTLEY QURESHI CONSULTANTS
                          processes any personal data relating to you, and to request a copy of that personal data; ask to
                          change or update data that is inaccurate, incomplete or outdated or to erase or restrict the use of
                          your personal data in certain circumstances provided by law;
                      </li>
                      <li>
                          The right to request that we export to another company, where technically feasible, your
                          personal data that we hold in order to provide our services to you.
                      </li>
                  </ul>
              </p>
              <p>
              Where the processing of your personal data is based on your previously given consent, you
              have the right to withdraw your consent at any time. You may also have the right to object to
              the processing of your personal data on grounds relating to your particular situation.
              In order to exercise your data protection rights, you may contact KURTLEY QURESHI
              CONSULTANTS as described in the Contact Us section below. We take each request seriously.
              We will comply with your request to the extent required by applicable law. We will not be able
              to respond to a request if we no longer hold your personal data.
              For your protection, we may need to verify your identity before responding to your request,
              such as verifying that the email address from which you send the request matches your email
              address that we have on file. If we no longer need to process personal data about you in order to
              provide our services, we will not maintain, acquire or process additional information in order to
              identify you for the purpose of responding to your request.
              </p>
              <p>
                  If you are a customer of a KURTLEY QURESHI CONSULTANTS client (“Client”), please
              direct your requests directly to the Client.
              </p>

              <h2>Access to information</h2>
              <p>
              You have the right to access information held about you. Your right of access can be exercised
              in accordance with the applicable legislation. Any access request may be subject to a fee to
              meet our costs in providing you with details of the information we hold about you.
              </p>

              <h2>International data transfers</h2>
              <p>
              KURTLEY QURESHI CONSULTANTS is a global business. Therefore, your personal data
              may be stored and processed in any country where we have operations or where we engage
              service providers. We may transfer your personal data that we maintain about you to recipients
              in countries other than the country in which the personal data was originally collected. Those
              countries may have data protection rules that are different from those of your country.
              However, we will take measures to ensure that any such transfers comply with applicable data
              protection laws and that your personal data remains protected to the standards described in this
              Privacy Policy. In certain circumstances, courts, law enforcement agencies, regulatory agencies
              or security authorities in those other countries may be entitled to access your personal data.
              </p>

              <h2>Changes to our Privacy Policy</h2>
              <p>
              Any changes we may make to our Privacy Policy in the future will be posted on this page and,
              where appropriate, notified to you by e-mail. Please check back frequently to see any updates or
              changes to our Privacy Policy.
              </p>

              <h2>Links to other websites</h2>
              <p>
              Our website may, from time to time, contain links to and from the websites of our partner
              networks, advertisers and affiliates. If you follow a link to any of these websites, please note
              that these websites have their own privacy policies and that we do not accept any responsibility
              or liability for these policies. Please check these policies before you submit any personal data to
              these websites.<br/>
              Data Controller<br/>
              The personal data controller is: KURTLEY QURESHI CONSULTANTS Ltd Corporate
              identity number BC1395192<br/>
              Contact If you have any questions regarding our Privacy Policy, please contact us by email at
              <a href='mailto:office@finstee.com' target='_blank' rel="noreferrer">office@finstee.com</a>
              </p>
          </div>
      </Page>
  )
}