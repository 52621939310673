import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./pages/home";
import {AboutUs} from "./pages/about-us";
import {OurServices} from "./pages/our-services";
import {ContactUs} from "./pages/contact-us";
import {NotFound} from "./pages/not-found";
import {Soon} from "./pages/soon";
import './index.css'
import {TermsAndConditions} from "./pages/terms-and-conditions";
import {PrivacyPolicy} from "./pages/privacy-policy";
import {LegalNotice} from "./pages/legal-notice";
import {RiskAppetitePolicy} from "./pages/risk-appetite-policy";
import {CookiesPolicy} from "./pages/cookies-policy";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/about-us",
        element: <AboutUs/>,
    },
    {
        path: "/our-services",
        element: <OurServices/>,
    },
    {
        path: "/contact-us",
        element: <ContactUs/>,
    },
    {
        path: "/terms-and-conditions",
        element: <TermsAndConditions/>,
    },
    {
        path: "/soon",
        element: <Soon/>,
    },
    {
        path: "/legal-notice",
        element: <LegalNotice/>,
    },
    {
        path: "/risk-appetite-policy",
        element: <RiskAppetitePolicy/>,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>,
    },
    {
        path: "/cookies-policy",
        element: <CookiesPolicy/>,
    },
    {
        path: "*",
        element: <NotFound/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleReCaptchaProvider
          reCaptchaKey='6Ld0ew4mAAAAAEj3hvL4_6lcrmOIqeQRNdPbi9IH'
          container={{
              parameters: {
                  badge: 'bottomleft',
              },
          }}
      >
        <RouterProvider router={router}/>
      </GoogleReCaptchaProvider>
  </React.StrictMode>
);