import {Page} from "../components/page";

export const LegalNotice = () => {
  return (
      <Page>
          <div className="policy-container">
              <h1>Legal Notice</h1>
              <p>
                  KURTLEY QURESHI CONSULTANTS is a private limited company registered as a Money
                  service   business   by   the   Canada’s   Financial   Intelligence   Unit:   FINTRAC   (Financial
                  Transactions and Reports Analysis Centre of Canada) and is authorized to provide the financial
                  services as follows:
                  <ul>
                      <li>Foreign exchange dealing</li>
                      <li>Money transferring</li>
                      <li>Payment service provider</li>
                  </ul>
                  KURTLEY QURESHI CONSULTANTS details are available after entering its name on the
                  FINTRAC   website: <a rel="noreferrer" href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3137303038362c227072696d617279536561726368223a7b226f72674e616d65223a22554e49544544204d4f4e4559205345525649434553222c2273656" target="_blank">https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3137303038362c227072696d617279536561726368223a7b226f72674e616d65223a22554e49544544204d4f4e4559205345525649434553222c2273656172636854797065223a317d7d/</a>
                  has to fulfill specific obligations as required by the Proceeds of
                  Crime   (Money   Laundering)   and   Terrorist   Finanaacing   Act   (PCMLTFA)   and   associated
                  Regulations and report to the FINTRAC.
              </p>

              <h2>RESPONSIBILITY</h2>
              <p>
                  The information provided on this site should not be considered as commercial offers of products
                  or services. KURTLEY QURESHI CONSULTANTS also indicates that the content of the site is
                  distributed   for   informational   purposes   only   and   therefore   cannot   be   used   to   hold   the
                  responsibility of the site owner.
                  Any person willing to use one of the products or services mentioned on the site is invited to
                  contact the KURTLEY QURESHI CONSULTANTS to be informed of all the terms and
              </p>
          </div>
      </Page>
  )
}