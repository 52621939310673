import process from '../assets/clipboard_front.png';
import support from '../assets/message_front.png';
import lock from '../assets/lock_front.png';
import wallet from '../assets/wallet_left.png';
import snowCristalBg from '../assets/snow-cristal-bg.png';

export const ReasonsList = ({withBg}) => {
  return (
      <div className="reasons-list">
          <div className="reason-row">
              <div className="reason">
                  <img src={process} alt="process"/>
                  <h4>Simple application process</h4>
                  <p>It will take up to 10 minutes to register a profile on the Finstee website. You can complete the verification remotely from anywhere in the world using any device.</p>
              </div>
              <div className="reason">
                  <img src={support} alt="support"/>
                  <h4>24/7 Support</h4>
                  <p>We provide our clients with 24/7 support. Finstee's skilled team helps with any questions regarding personal and business accounts, money exchange and transfers, etc.</p>
              </div>
          </div>
          <div className="reason-row">
              <div className="reason">
                  <img src={lock} alt="secure"/>
                  <h4>100% Secure</h4>
                  <p>We are regulated by FINTRAC and maintain 100% liquidity. We also integrate world practices to protect your money and data.</p>
              </div>
              <div className="reason">
                  <img src={wallet} alt="finance"/>
                  <h4>Constant access to finance</h4>
                  <p>Our clients receive real-time information about their finances. In a personal account, you can check bank account details, transaction information and analyse dashboards.</p>
              </div>
          </div>
          {withBg && <img className='bg' src={snowCristalBg} alt="snow-cristal-bg"/>}
      </div>
  )
}