import {Page} from "../components/page";

export const TermsAndConditions = () => {
  return (
      <Page>
          <div className="policy-container">
              <h1>Terms & Conditions</h1>
              <p>
                  Valid from May 1st, 2023
              </p>
              <p>
                  These Terms and Conditions (hereinafter referred to as the “Terms”) and each of
                  the documents We refer to in them are our standard client agreement, which
                  creates a legally binding agreement between You (hereinafter referred to as “You”
                  or the “Client”) and KURTLEY QURESHI CONSULTANTS LIMITED Ltd (hereinafter
                  referred to as “We” or “KURTLEY QURESHI CONSULTANTS”) relating to the use of
                  payment services provided by Us. References hereinafter to the “Parties” are a
                  reference to You and KURTLEY QURESHI CONSULTANTS.
              </p>
              <p>
                  For Your own benefit and protection please read these Terms and each of the
                  documents We refer to carefully before accepting them. If You do not understand
                  any provision contained herein, please do not hesitate to ask for further information
                  before proceeding. If You have accepted the Terms and registered with KURTLEY
                  QURESHI CONSULTANTS  system, it shall be deemed that You have carefully read
                  the Terms and any other documents We refer to in them, and thus You confirm that
                  all provisions set forth herein have been discussed with You, are clear and fully
                  understandable to You and You fully agree with them.
              </p>
              <p>
                  KURTLEY QURESHI CONSULTANTS Ltd is a company incorporated in Canada with
                  registered number BC1395192, whose registered office address is at 777 HORNBY
                  STREET VANCOUVER BC V6Z 1S4 CANADA. KURTLEY QURESHI CONSULTANTS Ltd. is
                  registered as Money Services Business (“MSB”) with the Financial Transactions and
                  Reports Analysis Centre (“FINTRAC”) (Reg. No.: M23111500)
              </p>
              <p>
                  You can contact Us at out registered offices address: 777 HORNBY STREET
                  VANCOUVER BC V6Z 1S4 CANADA  or also by email at: <a rel="noreferrer" href='mailto:office@finstee.com' target='_blank'>office@finstee.com</a>
              </p>

              <h2>1. DEFINITIONS AND MEANINGS</h2>
              <p>
                  1.1. Application is a form available on KURTLEY QURESHI CONSULTANTS Website
                  for registration in the System and Account opening which should be filled in by the
                  Client or its representative.<br/>
                  1.2. Business Day is any calendar day other than a Saturday or a Sunday or a
                  public or bank holiday in Canada, on which the payment infrastructures and the
                  banks are open for business in Canada, or any other holiday previously announced
                  by KURTLEY QURESHI CONSULTANTS.<br/>
                  1.3. Client is a private individual or legal entity in whose name Client Account is
                  opened and maintained, saving provisions of Cl. 3.4. Hereof.<br/>
                  1.4. Client Account is a payment account in KURTLEY QURESHI CONSULTANTS
                  system that the Client opens and maintains through the Platform.<br/>
                  1.5. Client’s Identification is a set of Know Your Customer (KYC) and Due
                  Diligence (DD) measures applied by KURTLEY QURESHI CONSULTANTS for the
                  purposes   to   prevent   money   laundering   and   terrorism   financing   subject   to
                  mandatory binding requirements of applicable Anti-Money Laundering and Counter
                  Terrorism Financing (AML/CTF) legal acts and regulations.<br/>
                  1.6. Client’s Representative is a person duly authorised by the Client to
                  represent the Client in relationship with KURTLEY QURESHI CONSULTANTS and
                  execute Payment Transactions of the name and for the benefit of the Client, acting
                  under a power of attorney or another legal base.<br/>
                  1.7. Customer Service is KURTLEY QURESHI CONSULTANTS customer service,
                  which the Client can reach by sending a message to the email indicated on the
                  Website in the “Contact Us” section.<br/>
                  1.8. FINTRAC means the Financial Transactions and Reports Analysis Centre of
                  Canada, which address is FINTRAC 24th floor, 234 Laurier Avenue West Ottawa ON,
                  K1P 1H7 CANADA; further information on the FINTRAC can be obtained on the
                  website at <a rel="noreferrer" href="https://fintrac-canafe.canada.ca/intro-eng" target="_blank">https://fintrac-canafe.canada.ca/intro-eng</a> .
                  Telephone: <a rel="noreferrer" href="tel:1-866-346-8722">1-866-346-8722</a> (toll free).<br/>
                  1.9. Fees are charges payable by the Client to KURTLEY QURESHI CONSULTANTS
                  for using KURTLEY QURESHI CONSULTANTS services, available on the Platform
                  section “Fees”.<br/>
                  1.10. General Conditions of the Website are general conditions of use of the
                  Website, including access to the website and cookies use. The said conditions are
                  available on the Website.<br/>
                  1.11. KURTLEY QURESHI CONSULTANTS means KURTLEY QURESHI CONSULTANTS
                  Limited, a company incorporated in Canada with registered number BC1395192 ,
                  whose registered office address is at  701 WEST GEORGIA STREET, SUITE 1500
                  VANCOUVER, BC, CANADA V7Y1C6. KURTLEY QURESHI CONSULTANTS. is registered
                  as Money Services Business (“MSB”) with the Financial Transactions and Reports
                  Analysis Centre (“FINTRAC”) (Reg. No.: M23111500)<br/>
                  1.12. Order   is   an   instruction   given   by   the   Client   to   KURTLEY   QURESHI
                  CONSULTANTS via the System for the purpose of carrying out a Payment
                  Transaction. An Order may not be withdrawn by the Client after the date on which it
                  is deemed irrevocable as indicated in these Terms.<br/>
                  1.13. Payer is a private individual or legal entity which executes payment for the
                  benefit of the Client or KURTLEY QURESHI CONSULTANTS’s Client which submits an
                  Order.<br/>
                  1.14. Payment Instrument is an electronic instrument allowing the Client to access
                  to funds held in the Client Account by using an electronic device and/or electronic
                  communication channels. Payment Instrument means, in particular, a money order,
                  payment card, payment of funds.<br/>
                  1.15. Party means the Client or KURTLEY QURESHI CONSULTANTS, named
                  together Parties.<br/>
                  1.16. Payment Transaction is a money transfer initiated by the Client.<br/>
                  1.17. Payment Transfer is a payment transaction wherein funds are transferred to
                  a payment account.<br/>
                  1.18. Personal Data means any information that relates to an identified or
                  identifiable living individual, also different pieces of information, which collected
                  together can lead to the identification of a particular person. Personal Data
                  processing principles are defined in KURTLEY QURESHI CONSULTANTS Privacy
                  Policy.<br/>
                  1.19. Platform is KURTLEY QURESHI CONSULTANTS payment service environment
                  that enables the Client to open and maintain Client Account.<br/>
                  1.20. Privacy Policy is the KURTLEY QURESHI CONSULTANTS internal policy
                  governing the processing of personal data, which is available on the Website, as
                  may be amended from time to time. Privacy Policy is based on the Personal
                  Information Protection and Electronic Documents Act, SC 2000 c 5 (PIPEDA)
                  (<a rel="noreferrer" href="https://laws-lois.justice.gc.ca/ENG/ACTS/P-8.6/index.html" target="_blank">https://laws-lois.justice.gc.ca/ENG/ACTS/P-8.6/index.html</a>)   and   common   good
                  practice subject to Personal Data protection. [((type)) Annotation]<br/>
                  1.21. Profile is the single, centralized place on the Platform to manage all
                  information about the Client, including Client Account number(s), ancillary services
                  provided, personal and contact data, transactions history, any special conditions
                  and other required information.<br/>
                  1.22. Recipient is a private individual or legal entity specified in the Order as
                  recipient of funds of the Payment Transaction.<br/>
                  1.23. Read-only mode means the account status with limited functions, access to
                  which the Client receives after the phone and email registration and before full
                  registration, identification and account opening verification.<br/>
                  1.24. Services are the payment services that the Company is authorized to
                  perform, including money transferring, foreign exchange dealing.<br/>
                  1.25. Strong Customer Authentication (SCA) is a verification method based on
                  guidelines set down by FINTRAC (<a rel="noreferrer" href="https://www.fintrac-canafe.gc.ca" target="_blank">http://www.fintrac-canafe.gc.ca</a> ). The SCA
                  involves using of two or more elements that are independent, in that the breach of
                  one element does not compromise the reliability of any other element and designed
                  in such a way as to protect the confidentiality of the authentication data. Rules for
                  Strong Customer Authentication are set in line with the Second Payment Services
                  Directive (PSD2) implemented across EU member states (Directive (EU) 2015/2366).<br/>
                  1.26. Terms and Conditions are the Terms and Conditions, published on the
                  Website that may be amended from time to time.<br/>
                  1.27. Website is the KURTLEY QURESHI CONSULTANTS website available at
                  <a rel="noreferrer" href="https://www.northpeakpay.com/" target="_blank">_________________.com</a><br/>
                  1.28. Working Hours mean a time from 9.00 AM to 7.00 PM EET (Eastern European
                  Time) on each Business Day, when KURTLEY QURESHI CONSULTANTS provides
                  Services to the Clients, handles Orders and executes Payment Transactions.<br/>
                  1.29. Withdrawal is removing funds from the Client Account which can be carried
                  out over a period of time in fixed or variable amounts within the established limit in
                  the form of a money transfer only.
              </p>

              <h2>2. SUBJECT AND SCOPE OF THE TERMS AND CONDITIONS</h2>
              <p>
                  2.1. The subject of the Terms and Conditions is to provide the Client with
                  KURTLEY QURESHI CONSULTANTS services in order to send and receive electronic
                  money payments and other services provided by KURTLEY QURESHI CONSULTANTS,
                  as well as conditions upon which the Client Account is opened and maintained
                  (opening and closure).<br/>
                  2.2. The Terms and Conditions are concluded when the Client submits an
                  Application for Client Account services.<br/>
                  2.3. The Terms and Conditions is a contract between the Client and KURTLEY
                  QURESHI CONSULTANTS. It sets out the terms and conditions that apply to the
                  Client’s use of KURTLEY QURESHI CONSULTANTS services. The Client agrees that
                  the Client’s use of KURTLEY QURESHI CONSULTANTS services shall constitute the
                  Client’s acceptance of the Terms and Conditions. Before the Client signs up for
                  KURTLEY QURESHI CONSULTANTS services, the Client must read any updates of the
                  Terms and Conditions on the Platform. All future changes set out in an update are
                  incorporated by reference into the Terms and Conditions. The changes in the Terms
                  and Conditions will take effect on the date specified by KURTLEY QURESHI
                  CONSULTANTS.<br/>
                  2.4. The Terms and Conditions govern the opening, use and closure of Client
                  Account and other related payment services as referred to herein. [((type)) Annotation]<br/>
                  2.5. Together with agreements concluded, Privacy Policy, and any other
                  conditions and documents (Supplements, Agreements, Rules, Declarations, etc.),
                  including but not limited to, information on the websites, they constitute the legal
                  relationship between the Client and KURTLEY QURESHI CONSULTANTS.<br/>
                  2.6. The Client is advised to read the Terms and Conditions carefully. If there is
                  any part of the Terms and Conditions the Client does not understand or wish to
                  clarify, the Client is advised to contact KURTLEY QURESHI CONSULTANTS.<br/>
                  2.7. The Client is advised to print or download and keep a copy of the Terms and
                  Conditions and Fees for future reference. The Client can always view the current
                  version of Terms and Conditions on the Website.<br/>
                  2.8. Depending on the type of Client Account the Client has, additional terms
                  and conditions and agreements may apply.<br/>
              </p>

              <h2>3. CLIENT ACCOUNT</h2>
              <p>
                  3.1. Client Account is an payment account that enables the Client to hold stored
                  value (known as "funds", "money"), to send and receive payments. The Client can
                  check the balance of funds and view the transactions carried out on the Client
                  Account via the Platform.<br/>
                  3.2. Subject to section 10, the money held on Client Account does not expire.<br/>
                  3.3. The Client has the right to withdraw funds from Client Account at any time.
                  However, the Client may be required to confirm the Client’s identity and present
                  any supporting documentation beforehand. There is no minimum withdrawal
                  amount, but the funds on Client Account must be sufficient to cover any applicable
                  withdrawal fee.<br/>
                  3.4. Client  Account  may  be  subject  to  limits  set  by  KURTLEY  QURESHI
                  CONSULTANTS and depending on the Client’s AML Risk type, assessed by KURTLEY
                  QURESHI CONSULTANTS AML team subject to mandatory binding AML/CTF legal
                  requirements applicable to KURTLEY QURESHI CONSULTANTS. The limits may be
                  revised by KURTLEY QURESHI CONSULTANTS from time to time.<br/>
                  3.5. Client Account is opened for indefinite period of time.<br/>
                  3.6. Client Account is issued by KURTLEY QURESHI CONSULTANTS and it is not a
                  bank account. Although it is a service regulated by the FINTRAC, by accepting the
                  Terms and Conditions, the Client acknowledges that Client Account and the funds
                  stored on it is not covered by the Canadian Deposit Insurance Corporation (CDIC)
                  scheme. No other compensation scheme exists to cover losses claimed in
                  connection with Client Account. Although Client Account is not covered by CDIC,
                  KURTLEY QURESHI CONSULTANTS ensures that once it receives the Client’s funds
                  they are held in a segregated account and safeguarded in accordance with the
                  applicable legislation until they are spent or withdrawn or reimbursed to the Client.
                  If KURTLEY QURESHI CONSULTANTS becomes insolvent, funds that the Client loaded
                  on to the Client’s Client Account will be protected for the Client against the claims
                  of any creditors of KURTLEY QURESHI CONSULTANTS as they are held in a
                  safeguarded account.<br/>
                  3.7. The money on Client Account belongs to the natural person or legal entity,
                  which is registered as Client Account holder (the Client). No person other than Client
                  Account holder has any rights in relation to the funds held in Client Account, except
                  in cases of succession. The Client may not assign or transfer Client Account to a
                  third party or otherwise grant any third party a legal or equitable interest over it.<br/>
                  3.8. Any funds held on Client Account will not earn any interest.<br/>
                  3.9. There is Private Client Account and Corporate Client Account.
                  <span>
                      3.9.1. Both of the Client Account would require the Client to state his/her
                      identity: name, surname, date of birth, country of residence and both
                      his/her cell phone number, email address, provide his/her audiovisual
                      recording in good quality with appropriate lighting for KURTLEY QURESHI
                      CONSULTANTS to identify the Client, good quality photo (or scanned copy)
                      of ID document or passport, which is valid travel document for crossing
                      the external border of the country of residence of the Client, a scanned
                      copy of utility bill, dated within the last 3 months.<br/>
                      3.9.2. Corporate Client Account requires the Client to submit application form,
                      documents and information set by KURTLEY QURESHI CONSULTANTS.
                  </span>
                  3.10. In case the potential Client has not submitted the proof of identity and/or
                  proof   of   address,   KURTLEY   QURESHI   CONSULTANTS   does   not   process   the
                  application further.<br/>
                  3.11. During the verification of the document KURTLEY QURESHI CONSULTANTS
                  checks that:
                  <span>
                      3.11.1. Document is valid;<br/>
                      3.11.2. There are no obvious signs of forgery (strange smears, document is
                      damaged etc.);<br/>
                      3.11.3. Quality of the documentation (regarding the content, quality, possible
                      mistakes) complies with the legislation. KURTLEY QURESHI CONSULTANTS
                      does not process the application further if documents do not include all of
                      the required pages.
                  </span>
                  3.12. Client confirms that:
                  <span>
                      3.12.1. Document includes all necessary pages, grading scales, legends and
                      translations.<br/>
                      3.12.2. The file is complete, that all scanned images are correctly oriented
                      (portrait or landscape) and that all content is readable before submitting
                      to KURTLEY QURESHI CONSULTANTS. Client should ensure that all 4 (four)
                      corners of the document are visible.
                  </span>
                  3.13. In case, either the proof of identity and/or proof of address cannot be
                  accepted for any reason (e.g., ID photo does not look genuine, etc.), KURTLEY
                  QURESHI CONSULTANTS has the right to deny the application.<br/>
                  3.14. KURTLEY QURESHI CONSULTANTS reserves the right not to make a report to
                  authorities, because during this stage of establishing relationships with potential
                  Client, KURTLEY QURESHI CONSULTANTS cannot be sure that unwillingness of the
                  potential Client is related to the complying with the demands (standards) of
                  legislation, and not related with unwillingness to cooperate with KURTLEY QURESHI
                  CONSULTANTS.<br/>
              </p>

              <h2>4. APPLYING FOR CLIENT ACCOUNT AND OPENING CLIENT ACCOUNT</h2>
              <p>
                  4.1. In order to use KURTLEY QURESHI CONSULTANTS’s services the Client must
                  first open Client Account by registering the Client’s details in the Platform and
                  submitting an Application for account services. As part of the Client Account
                  opening process, the Client will need to accept the Terms and Conditions and to pay
                  document verification Fee for opening an account by Payment Transfer. The Client
                  must not be in breach of any of the Terms and Conditions. KURTLEY QURESHI
                  CONSULTANTS may refuse to provide services to residents of countries mentioned
                  in the FATF blacklist, OFAC Sanctions List and other countries mentioned in various
                  sanction lists or in recommendations of regulating authorities against cooperation.
                  Document verification Fee for opening an account is not refundable.<br/>
                  4.2. If the Client is a private individual, the Client must be at least 18 years old
                  to use KURTLEY QURESHI CONSULTANTS services, and by opening Client Account
                  the Client declares that the Client is 18 years old.<br/>
                  4.3. To become a Client, the Client must apply for Client Account by providing all
                  of the information requested on the registration stage of Website. All information
                  the Client  provides during the signup process or any time thereafter must be
                  accurate and truthful. The Client is prohibited from providing any false, inaccurate,
                  incomplete or misleading information.<br/>
                  4.4. After the successful verification and approval, the Client gets access to the
                  account.<br/>
                  4.5. The Client may only open Client Account if it is legal to do so in the Client’s
                  country of residence. By opening Client Account the Client represents and warrants
                  to KURTLEY QURESHI CONSULTANTS that the Client’s opening of Client Account
                  does not violate any laws or regulations applicable to the Client. The Client shall
                  indemnify KURTLEY QURESHI CONSULTANTS against any losses KURTLEY QURESHI
                  CONSULTANTS incurs in connection with the Client’s breach of this section.<br/>
                  4.6. The Client may not be able to use Client Account or any part of its services
                  until the Client has passed all KURTLEY QURESHI CONSULTANTS’s identity and
                  security validation and verification checks, and provided information requested in
                  accordance   with   anti-money   laundering   regulations.   KURTLEY   QURESHI
                  CONSULTANTS may check all personal and identity verification information the
                  Client gives us with credit reference or fraud prevention agencies and other
                  organizations. These agencies may keep a record of the Client’s information and the
                  searches done. However, KURTLEY QURESHI CONSULTANTS’s does not perform a
                  credit check and any search is for identity verification purposes only. KURTLEY
                  QURESHI CONSULTANTS shall keep records of the information and documents
                  KURTLEY   QURESHI   CONSULTANTS   obtains   to   verify   the   Client’s   identity   in
                  accordance with all applicable legal and regulatory requirements.<br/>
                  4.7. For additional Account opening possibility Client shall contact Customer
                  Service. KURTLEY QURESHI CONSULTANTS has the right to refuse opening of
                  additional accounts.<br/>
                  4.8. The Client may only add payment instruments to Client Account if the Client
                  is the legal holder of that Payment Instrument. KURTLEY QURESHI CONSULTANTS
                  takes any violation of this requirement very seriously and will treat any attempt to
                  add a payment instrument of which the Client is not the legal holder as a fraudulent
                  act.<br/>
                  4.9. The Client is using Client Account for commercial purposes if the Client is
                  receiving or transferring payments for or in connection with any business activity.
                  KURTLEY QURESHI CONSULTANTS reserves the right to determine whether, in
                  KURTLEY QURESHI CONSULTANTS reasonable opinion, the Client is using Client
                  Account for commercial purposes. If the Client is in any doubt about whether or not
                  an activity amounts to a commercial activity, the Client should contact Customer
                  Service.<br/>
              </p>

              <h2>5. MAINTAINING CLIENT ACCOUNT</h2>
              <p>
                  5.1. The Client confirms that the information on Client Account and Application
                  form is always accurate and up to date and KURTLEY QURESHI CONSULTANTS shall
                  not be liable for any loss arising out of the Client’s failure to do so. KURTLEY
                  QURESHI CONSULTANTS may ask the Client at any time to confirm the accuracy of
                  the Client’s information or to provide documents or other evidence.<br/>
                  5.2. KURTLEY QURESHI CONSULTANTS has the right to block Client Account at
                  any time, if any of the Clients provided documents are out of date, are unreadable,
                  does not look genuine, documents do not include all necessary pages, grading
                  scales, legends and translations etc. If the Client has not provided the needed
                  documents or they do not meet the required criteria mentioned in sections 3 and 5
                  of the Terms and Conditions, after receiving KURTLEY QURESHI CONSULTANTS
                  informative e-mail, the Client has 7 (seven) working days to correct deficiencies.<br/>
                  5.3. In case the Client Account has been blocked, after the Client has submitted
                  the needed documents, KURTLEY QURESHI CONSULTANTS shall unblock the Client
                  Account within 2 (two) working days from the date required documents were
                  received.<br/>
                  5.4. KURTLEY QURESHI CONSULTANTS may contact the Client by e-mail or in
                  other ways described in section 18 with information or notices regarding Client
                  Account. It is the Client’s responsibility to check regularly the proper functioning of
                  the Client’s e-mail address or other methods of communication that the Client has
                  registered with Client Account and to retrieve and read messages relating to Client
                  Account promptly. KURTLEY QURESHI CONSULTANTS shall not be liable for any loss
                  arising out of the Client’s failure to do so.<br/>
                  5.5. Payment Transactions are displayed in the Client’s online transactions
                  history together with the fees charged. Each transaction is given a unique
                  transaction ID and shown in the transaction history. The Client should quote this
                  transaction ID when communicating with KURTLEY QURESHI CONSULTANTS about a
                  particular transaction. The Client should check Client Account balance and
                  transaction history regularly. The Client should report any irregularities or clarify
                  any questions the Client has as soon as possible by contacting Customer Service.<br/>
                  5.6. Subject to the provisions of section 7 below, in order to claim a refund for an
                  unauthorised or incorrectly executed payment transaction on Client Account the
                  Client must notify KURTLEY QURESHI CONSULTANTS without undue delay after
                  becoming aware of the unauthorised or incorrect transaction and in any event no
                  later than six (6) months after the debit date of the transaction.<br/>
              </p>

              <h2>6. KEEPING CLIENT ACCOUNT SAFE</h2>
              <p>
                  6.1. The Client must take all reasonable steps to keep Client Account password
                  safe at all times and never disclose it to anyone. Any message the Client receives or
                  website the Client visits that asks for the Client’s password, other than the Platform,
                  should be reported to KURTLEY QURESHI CONSULTANTS. If the Client is in doubt
                  whether a website is genuine, the Client should contact the Customer Service. It is
                  advisable to change the Client’s password regularly (at least every three (3) to six
                  (6) months) in order to reduce the risk of a security breach in relation to Client
                  Account.<br/>
                  6.2. KURTLEY QURESHI CONSULTANTS also advises the Client not to choose a
                  password that is easily guessed from information someone might know or gather
                  about the Client’s or a password that has a meaning. The Client must never allow
                  anyone to access Client Account or watch the Client accessing Client Account.<br/>
                  6.3. If the Client has any indication or suspicion of Client Account, login details,
                  password or other security feature being lost, stolen, misappropriated, used without
                  authorization, or otherwise compromised, the Client is advised to change the
                  password.<br/>
                  6.4. The Client must contact Customer Service without undue delay on becoming
                  aware of any loss, theft, misappropriation or unauthorised use of Client Account,
                  login details, password, or other security features. Any undue delay in notifying
                  KURTLEY QURESHI CONSULTANTS may not only affect the security of Client Account
                  but may result in the Client being liable for any losses as a result. If the Client
                  suspects that someone else accessed Client Account, the Client should also contact
                  the police and report about the incident.<br/>
                  6.5. KURTLEY QURESHI CONSULTANTS may suspend Client Account or otherwise
                  restrict its functionality on reasonable grounds relating to the security of Client
                  Account or any of its security features or if KURTLEY QURESHI CONSULTANTS
                  reasonably suspects that an unauthorised or fraudulent use of Client Account has
                  occurred or that any of its security features have been compromised. KURTLEY
                  QURESHI CONSULTANTS will notify the Client of any suspension or restriction and of
                  the reasons for such suspension or restriction in advance or, where KURTLEY
                  QURESHI CONSULTANTS is unable to do so, immediately after the suspension or
                  restriction has been imposed, unless notifying the Client would be unlawful or
                  compromise KURTLEY QURESHI CONSULTANTS’s reasonable security interests.
                  KURTLEY QURESHI CONSULTANTS will lift the suspension and/or the restriction as
                  soon as practicable after the reasons for the suspension and/or restriction have
                  ceased to exist.<br/>
                  6.6. The Client must take all reasonable care to ensure that the Client’s e-mail
                  address(es) is/are secure and only accessed by the Client, as the Client’s e-mail
                  address may be used to reset passwords or to communicate with the Client about
                  the security of Client Account. In case any of the e-mail addresses registered with
                  Client Account are compromised, the Client should without undue delay after
                  becoming aware of this contact Customer Service and also contact the Client’s e-
                  mail service provider.
              </p>

              <h2>7. SENDING PAYMENTS AND RECEIVING FUNDS</h2>
              <p>
                  7.1. To send a payment the Client is required to authorise the payment with the
                  Client’s login details and password. KURTLEY QURESHI CONSULTANTS may also ask
                  the Client additional security questions relating to the Client or Client Account. If
                  Client Account is protected by additional security measures such as Strong
                  Customer Authentication, the Client needs to follow the instructions provided to the
                  Client with such additional security measures. If Client Account is enabled to make
                  mass payments, the procedure to make such payments will be communicated to
                  the Client in the relevant integration manual. Client is responsible for the safety of
                  its electronic devices and keeping their passwords/access codes/Strong Customer
                  Authentication secret at all times and KURTLEY QURESHI CONSULTANTS is not
                  responsible of their theft or use by third parties as a result of Clients negligence,
                  misconduct   or   including   cases   when   third   parties   have   become   aware   of
                  password/access code contents and uses it to access Clients Client Account.<br/>
                  7.2. Every recipient of a payment the Client wishes to send through Client
                  Account must have a valid means that KURTLEY QURESHI CONSULTANTS can use
                  for his or her identification.<br/>
                  7.3. If the Client is asked to provide details of the recipient’s contact information,
                  requisites or other means of identification, where applicable, the Client must take
                  great care to properly type the exact details of who the Client wishes to send
                  money to. KURTLEY QURESHI CONSULTANTS uses those details as the unique
                  identifier to determine the intended recipient of the payment, which the Client
                  instructs KURTLEY QURESHI CONSULTANTS to process. Other information the Client
                  provides along with the recipient’s means of identification may be disregarded and
                  KURTLEY QURESHI CONSULTANTS shall not be liable for any error the Client makes
                  when entering the recipient’s means of identification.<br/>
                  7.4. If the payment details of the intended recipient are registered with KURTLEY
                  QURESHI CONSULTANTS, the funds will be instantly credited to Client Account
                  associated with payment details. Once funds are credited to the recipient’s Client
                  Account, the transaction becomes irreversible.<br/>
                  7.5. The Client's payment orders for transferring funds shall be executed no later
                  than the next Business Day.<br/>
                  7.6. Payments are subject to payment limits due to security and legal
                  requirements. These limits are set depending on the Client’s risk level.<br/>
                  7.7. KURTLEY   QURESHI   CONSULTANTS   will   deduct   the   value   of   Client’s
                  transactions from the balance on Client’s Client Account and any applicable Fees at
                  the time of the transaction. If the Client makes a transaction, any applicable Fees
                  will be added to the amount of the transaction so that the total sum of Client’s
                  transaction amount plus the applicable Fees will be deducted from the Client’s
                  Client Account balance.<br/>
                  7.8. If the Client is attempting a transaction in excess of the available balance on
                  the Client’s Client Account at the time the request is made plus any applicable Fees,
                  then the Client’s request will not be processed.<br/>
                  7.9. KURTLEY QURESHI CONSULTANTS may refuse to process the Client’s
                  transaction:<br/>
                  <span>
                      7.9.1. In case of insufficient funds on the Client’s Client Account at the time of a
                      transaction to cover the amount of the transaction and any applicable
                      Fees;<br/>
                      7.9.2. The Client fails to pay applicable Fees;<br/>
                      7.9.3. If KURTLEY QURESHI CONSULTANTS has reasonable grounds to believe
                      that the Client is acting in breach of the Terms and Conditions;<br/>
                      7.9.4. If KURTLEY QURESHI CONSULTANTS ascertains that a transaction is
                      suspicious or potentially illegal (for example, if KURTLEY QURESHI
                      CONSULTANTS ascertains that a transaction is being made fraudulently);
                      or because of errors, failures (whether mechanical or otherwise).<br/>
                  </span>
                  7.10. If KURTLEY QURESHI CONSULTANTS refuses to process a transaction
                  because KURTLEY QURESHI CONSULTANTS ascertains it is suspicious or potentially
                  illegal, where KURTLEY QURESHI CONSULTANTS is permitted to do so by applicable
                  law KURTLEY QURESHI CONSULTANTS will contact the Client. If KURTLEY QURESHI
                  CONSULTANTS refuses to process a transaction for any other reason, KURTLEY
                  QURESHI CONSULTANTS will inform the Client by email without undue delay and in
                  any event by the end of the next Business Day, giving KURTLEY QURESHI
                  CONSULTANTS reasons and explaining how the Client can correct any information
                  KURTLEY   QURESHI   CONSULTANTS   holds   that   led   to   KURTLEY   QURESHI
                  CONSULTANTS refusing to process the transaction, unless informing the Client
                  would compromise security measures or be unlawful.<br/>
                  7.11. The Client should regularly reconcile outgoing and incoming payments with
                  the Client’s own records.<br/>
                  7.12. According to section 13, sending payments is subject to Fees, including
                  currency conversion fees depending on the type of payment the Client makes, and
                  the type of Client Account the Client holds.<br/>
                  7.13. KURTLEY QURESHI CONSULTANTS hereby informs the Client that all
                  incoming payments are credited to the Client’s Client Account as follows:<br/>
                  <span>
                      7.13.1. Based only on the IBAN (for SEPA payments) or only on the Account<br/>
                      Number (for SWIFT, CHAPS, BACS, ACH and similar payments);
                      7.13.2.  Payments sent using SWIFT system must contain Client’s ID number
                      with KURTLEY QURESHI CONSULTANTS in the payment details.<br/>
                  </span>
                  7.14. Nevertheless, KURTLEY QURESHI CONSULTANTS is entitled to request
                  additional explanation before crediting the money to the Client Client Account in
                  case the IBAN or Account Number and the Client’s name fail to relate to each other.<br/>
                  7.15. If the incoming payment does not contain any one or several of the
                  indicators mentioned in clause 7.13.1 and/or 7.13.2 or such indicators are unclear
                  or inconsistent, KURTLEY QURESHI CONSULTANTS shall not credit the amount to the
                  Client’s Client Account and request the payer to provide clarifications. If the
                  clarifications, which are satisfactory to KURTLEY QURESHI CONSULTANTS, are not
                  received within 10 Business days following the day of the first request, KURTLEY
                  QURESHI CONSULTANTS shall return the payment to the payer. The fee for
                  returning and investigating the payment shall be deducted from the amount of the
                  money to be repaid.<br/>
              </p>

              <h2>8. PROHIBITED ACTIVITIES</h2>
              <p>
                  8.1. KURTLEY QURESHI CONSULTANTS has listed a number of activities that are
                  restricted or prohibited as they relate to the use of the KURTLEY QURESHI
                  CONSULTANTS Services, the terms of these terms and conditions, the applicable
                  laws, regulations, directives and decrees that may be issued from time to time by
                  KURTLEY QURESHI CONSULTANTS, the jurisdiction it is authorized, the jurisdictions
                  of its cross-border activities. The Client agrees that engaging in any of the below
                  activities, may cause the KURTLEY QURESHI CONSULTANTS service quality to suffer,
                  and that the Client may restrict the level of safe access that the Client or any other
                  client may enjoy. Such activities include:
                  <span>
                      (a) Violating any law, regulation, directive, statute or contract in relation to the
                      Service received, including without limitation, money laundering laws and
                      regulations, consumer and personal data protection;<br/>
                      (b) Acting in a manner that is offensive, harassing or demeaning to other Clients,
                      to KURTLEY QURESHI CONSULTANTS and/or its partners and third parties;<br/>
                      (c) Providing inaccurate, disingenuous, or entirely false (deliberately) information
                      without justifiable cause;<br/>
                      (d) Sending and receiving what may be deemed as unauthorised funds or funds
                      from fraudulent transactions;<br/>
                      (e) Not being cooperative with KURTLEY QURESHI CONSULTANTS when
                      requested to provide additional information that will allow KURTLEY QURESHI
                      CONSULTANTS to better familiarize itself with the nature of the Client’s business
                      and continuing operations;<br/>
                      (f) Refusing to provide confirmation of the Client’s identity and verification of the
                      Client’s details when requested upon, in order for KURTLEY QURESHI
                      CONSULTANTS to perform an investigation when needed; (g) Using tools and
                      mechanisms to conceal the Client’s online identity and location (use of proxy
                      and other anonymising technics);<br/>
                      (h) Controlling an Account with close links to another account. The close link in
                      this case exists when the accounts have a common person exercising control
                      and giving instructions, and/or the beneficial ownership of the account as
                      indicated by the common funding source is linked. Shared attributes are an
                      indication that close links between two or more accounts exist;<br/>
                      (i) Engaging in activities (in accordance with information in KURTLEY QURESHI
                      CONSULTANTS’s possession) that may present KURTLEY QURESHI CONSULTANTS
                      with increased risk of fraud or credit exposure that is beyond the acceptable
                      limits set by KURTLEY QURESHI CONSULTANTS;<br/>
                      (j) Using any device and/or software that intentionally or unintentionally
                      interferes with the proper operation of the Platform and KURTLEY QURESHI
                      CONSULTANTS website;<br/>
                      (k) Using the service in a manner that could present a risk of non-compliance
                      with the Company’s anti-money laundering, counter terrorist financing and other
                      relevant regulatory obligations. One such use and potential offense for not
                      complying with KURTLEY QURESHI CONSULTANTS’s obligation is the Client’s lack
                      of cooperation in providing identity confirmation details and the inability to
                      verify your identity potentially exposing KURTLEY QURESHI CONSULTANTS to the
                      risk of any regulatory fines by the authorities as a result of processing the
                      Client’s transactions);<br/>
                      (l) Using the KURTLEY QURESHI CONSULTANTS services in a manner that may
                      result in complaints, disputes, claims, reversals, chargebacks, fees, fines,
                      penalties and other liability to KURTLEY QURESHI CONSULTANTS, a Client, or a
                      third party; or<br/>
                      (m) Using the service in a manner that deviates from what is prescribed in these
                      Terms and Conditions.
                  </span>
                  8.2. The Client may not use KURTLEY QURESHI CONSULTANTS services if the
                  Client is residing in any of the countries of FATF blacklist, OFAC Sanctions List and
                  other countries mentioned in various sanction lists or in recommendations of
                  regulating authorities against cooperation. This list is not exhaustive and KURTLEY
                  QURESHI CONSULTANTS may decide in its sole discretion to discontinue or restrict
                  KURTLEY QURESHI CONSULTANTS’s services in other countries at any time and
                  without prior notice. KURTLEY QURESHI CONSULTANTS reserves the right to
                  suspend   or   terminate   Client   Account   at   any   time   if   KURTLEY   QURESHI
                  CONSULTANTS reasonably believe to be required to do so by law or in order to
                  comply with recommendations issued by a relevant government authority or
                  recognised body for the prevention of financial crime.<br/>
                  8.3. It is strictly forbidden to use Client Account for any illegal purposes including
                  but not limited to fraud and money laundering. KURTLEY QURESHI CONSULTANTS
                  will report any suspicious activity to the relevant law enforcement agency.<br/>
                  8.4. If the Client conducts or attempts to conduct any transaction in violation of
                  the prohibitions contained in this section 9, KURTLEY QURESHI CONSULTANTS
                  reserves the right to: reverse the transaction; and/or close or suspend Client
                  Account; and/or report the transaction to the relevant law enforcement agency
                  and/or the regulatory body; and/or claim damages from the Client; and charge the
                  Client a fee according to applicable Fees, in case KURTLEY QURESHI CONSULTANTS
                  applies any of the above.<br/>
                  8.5. It is the Client’s and not KURTLEY QURESHI CONSULTANTS responsibility to
                  ensure that the Client only sends payments to or receive payments from persons or
                  entities for the sale or supply of goods and services that the Client may provide or
                  receive in compliance with any applicable laws and regulations. The mere fact that
                  a person or entity accepts payments through Client Account is not an indication of
                  the legality of the supply or provision of their goods and services. If the Client is in
                  doubt as to the legality of a supply or purchase, the Client should not continue with
                  the Client’s payment. It is the Client’s and not KURTLEY QURESHI CONSULTANTS
                  responsibility to ensure bank account details are entered correctly to avoid
                  payment errors and any charges.
              </p>

              <h2>9. CURRENCY CONVERSION</h2>
              <p>
                  9.1. The Client may convert the money held in the Client’s Account into other
                  currencies KURTLEY QURESHI CONSULTANTS supports from time to time. The Client
                  may keep, or transfer converted money. The KURTLEY QURESHI CONSULTANTS is
                  entitled to carry out only non-cash money conversions.<br/>
                  9.2. The applicable exchange rate is available in the Platform and the Client has
                  to place a currency exchange order via the Platform in accordance with our Fees.
                  Transaction history is always available in the Profile.<br/>
                  9.3. Once the Client’s order to carry out the currency exchange has been
                  received by us it cannot be cancelled.<br/>
                  9.4. The KURTLEY QURESHI CONSULTANTS bears no responsibility if the Client
                  loses any money as a result of converting currency.<br/>
                  9.5. KURTLEY QURESHI CONSULTANTS would not be responsible for any losses:
                  <span>
                      9.5.1. In the event if KURTLEY QURESHI CONSULTANTS cannot provide Payment
                      Transactions in converted currency;<br/>
                      9.5.2. In the event if the Payment Transactions with converted currency is
                      returned to the Client. In this case the Client will be able to keep it or
                      convert it back to the original currency, each of these activities are
                      considered as separate transactions.<br/>
                      9.5.3. The Client agrees and accepts the risks of holding balances in multiple
                      currencies that (i) foreign currency exchange rates are subject to
                      fluctuations which are outside of KURTLEY QURESHI CONSULTANTS control
                      and (ii) past movements or trends in the movement of foreign currency
                      exchange rates cannot be taken as an indicator of future movements in
                      exchange  rates.  The  Client  agrees  to  not  use  KURTLEY  QURESHI
                      CONSULTANTS services for speculative trading.
                  </span>
              </p>

              <h2>10. TERMINATION, SUSPENSION AND CLOSING CLIENT ACCOUNT</h2>
              <p>
              10.1. KURTLEY QURESHI CONSULTANTS may terminate Client Account or any
              payment service associated with it by giving the Client one (1) month prior notice
              immediately if the Client is in breach of any of these terms or other terms
              applicable to the Client. The Client may terminate Client Account at any time.<br/>
              10.2. Together with a termination notice or at any time thereafter, KURTLEY
              QURESHI CONSULTANTS may give the Client reasonable instructions on how to
              withdraw remaining funds.<br/>
              10.3. KURTLEY QURESHI CONSULTANTS may suspend Client Account at any time
              immediately if KURTLEY QURESHI CONSULTANTS reasonably believes that:
              <span>
                  (i) Client Account has been compromised or for other security reasons; or<br/>
                  (ii) KURTLEY QURESHI CONSULTANTS reasonably suspects Client Account to
                  have been used or is being used without the Client’s authorisation or
                  fraudulently; or<br/>
                  (iii) we need to do so to comply with the law. KURTLEY QURESHI CONSULTANTS
                  shall notify the Client either prior to the suspension or, if prior notification
                  is not possible under the circumstances, promptly after the suspension
                  unless KURTLEY QURESHI CONSULTANTS is prohibited by law to notify the
                  Client.
              </span>
              10.4. KURTLEY QURESHI CONSULTANTS may at any time suspend or terminate
              Client Account without notice in case:
              <span>
                  10.4.1. KURTLEY QURESHI CONSULTANTS has reason to believe that the Client
                  is in any way involved in any fraudulent activity, money laundering,
                  terrorism financing or other criminal activity;<br/>
                  10.4.2. The Client violates or KURTLEY QURESHI CONSULTANTS has reason to
                  believe that the Client is in violation of any law or regulation that is
                  applicable to the Client’s use of KURTLEY QURESHI CONSULTANTS’s
                  services; or<br/>
                  10.4.3. The Client breaches any condition of the Terms and Conditions or any
                  other condition applicable to specific services covered by separate terms
                  and conditions.
              </span>
              10.5. If the Client’s Client Account is suspended or terminated the Client will not
              be able to use any of the functionality of the Client Account.<br/>
              10.6. When   Client   Account   is   terminated   (closed),   KURTLEY   QURESHI
              CONSULTANTS will immediately block the Client’s Client Account so it cannot be
              used. The Client will remain liable for all obligations relating to the Client’s Client
              Account even after Client Account has been terminated (closed). Terminating
              (closing) Client Account does not mean that KURTLEY QURESHI CONSULTANTS
              deletes the personal data that we hold on the Client and KURTLEY QURESHI
              CONSULTANTS will continue to store such data, including the history of the Client’s
              transactions for a minimum period of five (5) years as required by law.<br/>
              10.7. If Client Account holds a balance at the time of its closure, KURTLEY
              QURESHI CONSULTANTS will ask the Client to withdraw the Client’s funds within a
              reasonable period, during which Client Account will be accessible for withdrawing
              the remaining balance only. After the expiry of this period, the Client will not be
              able to access Client Account, but the Client may withdraw any remaining funds by
              contacting Customer Service and requesting that the funds be sent to the Client in a
              manner that is reasonably acceptable for KURTLEY QURESHI CONSULTANTS. The
              Client may do so for a period of five (5) years from the date of closure of Client
              Account but KURTLEY QURESHI CONSULTANTS suggests that the Client withdraws
              the Client’s remaining funds as soon as possible as they will not earn any interest
              while being deposited in Client Account. The Client’s obligations concerning keeping
              Client Account safe as set forth in section 6 shall continue to apply.<br/>
              10.8. If Client Account has not been active for the last six (6) months, it is
              classified as Dormant. This type of Client Account will be charged according to Fees.
              </p>

              <h2>11. FEES</h2>
              <p>
                  11.1. Fees depend on whether the Client is using Client Account for personal or
                  commercial purposes.<br/>
                  11.2. Transaction related fees could be viewed at any time on Platform section
                  “Fees”. Additional fees apply to Client Account used for commercial purposes in
                  accordance with the applicable terms and conditions. The Client should print or
                  download and keep a copy of the Fees section together with a copy of the Terms
                  and Conditions. For clarity, the Fees section forms part of the Terms and Conditions.
                  Fees are subject to change in accordance with section 15. Under certain
                  circumstances, KURTLEY QURESHI CONSULTANTS may charge additional fees as set
                  out in these Terms and Conditions.<br/>
                  11.3. The Client’s transactions may be subject to currency conversions. If the
                  Client makes a payment from Client Account denominated in one currency to Client
                  Account denominated in another currency, the Client will be asked to make the
                  payment either in the currency of Client Account or in another currency. If the Client
                  chooses the currency of Client Account, then the recipient will pay the fee for the
                  conversion into the currency of the recipient’s Client Account. If the Client chooses
                  the currency of the recipient’s Client Account, the Client will pay the fee for the
                  currency conversion into the currency of the payment. If the Client chooses a
                  currency, that is neither the currency of Client Account nor the currency of the
                  recipient’s Client Account then the Client will pay the fee for the conversion into the
                  currency of the payment, and the recipient will pay the fee for the conversion of the
                  payment currency into the currency of the recipient’s Client Account.<br/>
                  11.4. For every currency conversion, KURTLEY QURESHI CONSULTANTS will apply
                  its own exchange rates that are available on Platform section “Fees”. Changes in
                  these exchange rates may be applied immediately and without notice.<br/>
                  11.5. Fees are expressed either as a percentage of the transaction or as a fixed
                  amount in two main currencies, EUR or GBP, depending on the currency of the
                  transaction, that may be changed based on the amount and/or currency. Where
                  fixed fee amounts are displayed in a currency other than EUR or GBP, this is for
                  information purposes only. If fees are deducted from a balance or a transaction
                  denominated in a different currency, the EUR or GBP fee amount will be converted
                  into an equivalent fee in that other currency based on the KURTLEY QURESHI
                  CONSULTANTS exchange rates applicable at the time and available on the Platform
                  section “Fees”.<br/>
                  11.6. Fees payable by the Client will be deducted from Client Account balance and
                  the Client hereby authorises KURTLEY QURESHI CONSULTANTS to do the same.
                  Transaction fees will be charged when the transaction is executed. If Client Account
                  balance is insufficient to cover the Fees, KURTLEY QURESHI CONSULTANTS may
                  refuse to execute the payment.<br/>
                  11.7. In case the Clients does not have sufficient funds on the relevant Client
                  Account to cover the Fees and does not immediately upload sufficient funds to
                  cover these Fees, KURTLEY QURESHI CONSULTANTS has the right to deduct the
                  required amount from any of the Client’s Client Account.<br/>
              </p>

              <h2>12. COMPLAINTS</h2>
              <p>
                  12.1. Any complaints of the Client regarding KURTLEY QURESHI CONSULTANTS
                  services shall be submitted to the KURTLEY QURESHI CONSULTANTS in writing by e-
                  mail: <a rel="noreferrer" href='mailto:office@finstee.com' target='_blank'>office@finstee.com</a>. The Client should clearly indicate the date the problem
                  arose, the payment order number(s) and all necessary details if complaint is about
                  a specific transfer and what complaint is about.<br/>
                  12.2. KURTLEY QURESHI CONSULTANTS investigate the complaint, and Client will
                  get a response by email in accordance with the provisions of the Terms and
                  Conditions and KURTLEY QURESHI CONSULTANTS’s complaints policy.<br/>
                  12.3. KURTLEY QURESHI CONSULTANTS Complaint Handling Policy is available on
                  the Website.<br/>
              </p>

              <h2>13. CLIENT’S DATA</h2>
              <p>
                  13.1. The processing of the Client’s data is governed by the Privacy Policy, which
                  can be found on the Website. By accepting the Terms and Conditions, the Client
                  also agrees to the terms of the Privacy Policy. The Client should print and keep a
                  copy of the Privacy Policy together with the Terms and Conditions.<br/>
                  13.2. As a default, the Client will receive e-mail newsletters that will inform the
                  Client about new product features, events, promotions, special deals etc. By
                  accepting the Terms and Conditions, the Client agrees to receive such e-mail
                  newsletters on a regular basis. If the Client does not wish to receive any newsletters
                  from KURTLEY QURESHI CONSULTANTS, the Client can opt out at any time by
                  contacting Customer Service. Any e-mail newsletter the Client receives will also give
                  the Client the option to unsubscribe from any future newsletter.<br/>
                  13.3. All relevant Client’s data is updated and stored electronically according to
                  legal requirements.<br/>
                  13.4. The Client agrees that KURTLEY QURESHI CONSULTANTS carries out
                  processing of personal and other data in accordance with the Personal Information
                  Protection and Electronic Documents Act (S.C. 2000, c.5), known as PIPEDA, and
                  other applicable laws and regulations. KURTLEY QURESHI CONSULTANTS carries out
                  the processing of personal data of the Client, its representatives, authorized
                  persons, representatives, beneficial owners and other third parties related to the
                  Client, including the collection, registration, input, storage, arranging, modification,
                  using, transferring, transmission and disclosure of data, blocking or deletion, and
                  the Client is obliged to obtain consent to all data processing mentioned in this
                  section from all persons whose personal data they have transferred to KURTLEY
                  QURESHI CONSULTANTS. The purpose of personal data processing is compliance
                  with local and international laws and regulations, Client registration, the provision,
                  offering and maintenance of KURTLEY QURESHI CONSULTANTS products and
                  services,   the   security   of   employees,   the  security   of   KURTLEY   QURESHI
                  CONSULTANTS property (assets), the performance of the duties prescribed by law,
                  and the other legal interests of the KURTLEY QURESHI CONSULTANTS. The Personal
                  Data Controller is KURTLEY QURESHI CONSULTANTS.
              </p>

              <h2>14. LIABILITY</h2>
              <p>
                  14.1. In case of an unauthorised payment or a payment that was incorrectly
                  executed due to an error by KURTLEY QURESHI CONSULTANTS, KURTLEY QURESHI
                  CONSULTANTS shall at the Client’s request immediately refund the payment
                  amount including all fees deducted therefrom. This shall not apply:<br/>
                  <span>
                      14.1.1. If the Client fails to notify KURTLEY QURESHI CONSULTANTS without
                      undue delay of any loss of the Client’s password or other event that could
                      reasonably be expected to have compromised the security of Client
                      Account after the Client has gained knowledge of such event in which
                      case the Client shall remain liable for losses incurred up to the Client’s
                      notification to KURTLEY QURESHI CONSULTANTS;<br/>
                      14.1.2. If the transaction was unauthorised but the Client has compromised
                      the security of Client Account with intent or gross negligence in which
                      case the Client shall be solely liable for all losses.
                  </span>
                  14.2. Section 14.1.1 shall not apply to transactions made after the Client has
                  notified KURTLEY QURESHI CONSULTANTS in accordance with section 6.4 in which
                  case KURTLEY QURESHI CONSULTANTS shall remain liable and refund any
                  unauthorised transaction to the Client.<br/>
                  14.3. Without prejudice to the foregoing, the Client is asked to check the
                  transactions history of Client Account regularly and frequently and to contact
                  Customer Service immediately in case the Client has any questions or concerns.<br/>
                  14.4. In the case of any incorrect or misdirected payment, KURTLEY QURESHI
                  CONSULTANTS shall take reasonable measures to assist the Client with tracing and
                  recovering such payments.<br/>
                  14.5. Subject to the foregoing, KURTLEY QURESHI CONSULTANTS shall not be
                  liable for any disruption or impairment of KURTLEY QURESHI CONSULTANTS service
                  or for disruptions or impairments of intermediary services on which KURTLEY
                  QURESHI   CONSULTANTS   relies   for   the   performance   of   KURTLEY   QURESHI
                  CONSULTANTS’s obligations hereunder, provided that such disruption or impairment
                  is due to abnormal and unforeseeable circumstances beyond KURTLEY QURESHI
                  CONSULTANTS’s reasonable control, or the control of the intermediary affected.<br/>
                  14.6. KURTLEY QURESHI CONSULTANTS shall not be liable for any indirect or
                  consequential losses including but not limited to loss of profit, loss of business and
                  loss of reputation. KURTLEY QURESHI CONSULTANTS shall not be liable for any
                  losses arising from KURTLEY QURESHI CONSULTANTS’s compliance with legal and
                  regulatory requirements.<br/>
                  14.7. KURTLEY   QURESHI   CONSULTANTS’s   obligation   under   the   Terms   and
                  Conditions is limited to providing the Client with Client Account and related
                  payment services and does not make any statement in relation to or endorsement
                  of the quality, safety or legality of any goods or services provided by a KURTLEY
                  QURESHI CONSULTANTS customer (including merchant) or intermediary. If the
                  Client has any disputes about purchases made using the Client’s Client Account, the
                  Client should settle these with the merchant the Client bought the goods or services
                  from. KURTLEY QURESHI CONSULTANTS is not responsible for the quality, safety,
                  legality or any other  aspect of any goods or services purchased with the Client’s
                  Client Account. The Client must remember that once the Client has used Client
                  Account to make a purchase, KURTLEY QURESHI CONSULTANTS cannot stop that
                  transaction.<br/>
                  14.8. KURTLEY QURESHI CONSULTANTS shall not be liable for the assessment or
                  payment of any taxes, duties or other charges that arise from the underlying
                  commercial transaction between the Clients.<br/>
                  14.9. The Client agrees to defend, reimburse or compensate KURTLEY QURESHI
                  CONSULTANTS and hold KURTLEY QURESHI CONSULTANTS harmless from any
                  claim, demand, expenses or costs (including legal fees, fines or penalties) that
                  KURTLEY QURESHI CONSULTANTS incurs or suffers due to or arising out of the
                  Client’s or the Client’s agents' breach of the Terms and Conditions, breach of any
                  applicable law or regulation and/or use of the services. This provision shall survive
                  termination   of   the   relationship   between   the   Client   and   KURTLEY   QURESHI
                  CONSULTANTS.<br/>
                  14.10. KURTLEY QURESHI CONSULTANTS shall not be liable in contract, tort
                  (including negligence or breach of statutory duty) or otherwise for any indirect or
                  consequential loss or damage of any kind including punitive or exemplary damages
                  or for any loss of profit or loss of contract, loss of goodwill or reputation, loss of
                  opportunity, loss of revenue or third-party loss whether foreseeable or otherwise.<br/>
                  14.11. In case the Client is a legal entity, an officer(s) of the entity, who has(-ve)
                  signatory power, declare(s) to have full control and authorizations to act on behalf
                  of the entity, acknowledges and agrees that KURTLEY QURESHI CONSULTANTS will
                  not be used for transactions relating to: 1) Transactions (including sales) made
                  under a different trade name or business affiliation than indicated in this
                  application; 2) Any transactions that violates any law, ordinance, or regulation
                  applicable to the business.<br/>
                  14.12. Neither Party will be liable or responsible for any failure to perform, or delay
                  in performance of, any of their obligations under the Terms and Conditions that is
                  caused by an Event Outside the Control of a Party mentioned below.<br/>
                  14.13. An Event Outside the Control means any act or event beyond a Party’s
                  reasonable control, including without limitation strikes, lock-outs or other industrial
                  action by third parties, civil commotion, riot, invasion, terrorist attack or threat of
                  terrorist attack, war (whether declared or not) or threat or preparation for war, fire,
                  explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster,
                  or failure of public or private telecommunications networks.<br/>
                  14.14. If Event Outside the Control longs more than 30 (thirty) calendar days,
                  Parties may decide on termination of Business Relationship.
              </p>

              <h2>15. CHANGES TO THE TERMS AND CONDITIONS</h2>
              <p>
                  15.1. The Terms and Conditions and any additional terms and conditions that may
                  apply are subject to change. Changes will be implemented with prior notice from
                  KURTLEY QURESHI CONSULTANTS under the procedure set forth in this section.<br/>
                  15.2. KURTLEY QURESHI CONSULTANTS shall be entitled to unilaterally amend the
                  Terms and Conditions, given a 2 (two) month prior notice to the Client of any
                  change by sending an e-mail to the primary e-mail address registered with Client
                  Account.<br/>
                  15.3. The proposed change come into effect at the day set by KURTLEY QURESHI
                  CONSULTANTS. Changes that make the Terms and Conditions more favorable to the
                  Client come into effect immediately if so, stated in the change notice. Changes to
                  exchange rates come into effect immediately without notice and the Client shall not
                  have the right to object to such a change.<br/>
                  15.4. The Parties agree that the Client shall be deeded as having agreed with the
                  amendments, unless the Client notifies KURTLEY QURESHI CONSULTANTS to the
                  contrary before the date the amendments come to force. If the Client objects to the
                  changes, Client shall contact KURTLEY QURESHI CONSULTANTS customer service to
                  mutually resolve this issue, if no agreement can be made between Client and
                  KURTLEY QURESHI CONSULTANTS, Client’s Client Account shall be closed, and
                  cooperation terminated. Client Account will be closed in accordance with the
                  provisions of section 12 above.<br/>
                  15.5. The new version of Terms and Conditions shall replace these Terms and
                  Conditions unless otherwise agreed.<br/>
                  15.6. If KURTLEY QURESHI CONSULTANTS introduces new products or services
                  that does not affect or change the Terms and Conditions of Client’s Client Account,
                  KURTLEY QURESHI CONSULTANTS may inform the Client and add the product or
                  service immediately.
              </p>

              <h2>16. COMMUNICATION AND MISCELLANEOUS</h2>
              <p>
                  16.1. KURTLEY QURESHI CONSULTANTS communicates to the Client via e-mail or
                  through the Platform or via telephone. For this purpose, the Client at all times must
                  maintain at least one valid e-mail address in Client Account profile. The Client is
                  required to check for incoming messages regularly and frequently. E-mails may
                  contain links to further communication on the Platform. Any communication or
                  notice sent by e-mail or the Platform will be deemed received by the Client on the
                  same day it is sent to the Client’s e-mail inbox before 14:00 (EET) on a Business
                  Day. If it is sent to the Client’s email inbox after 14:00 (EET) on a Business Day or at
                  any other time, it will be deemed received on the next Business Day.
                  16.1.1. The Client agrees that KURTLEY QURESHI CONSULTANTS shall use the
                  e-mail address, postal address and phone number of the Client indicated
                  in the Application form (if applicable) to inform the Client on services
                  offered by KURTLEY QURESHI CONSULTANTS. The Client agrees to provide
                  additional information on the Platform or by other means if KURTLEY
                  QURESHI CONSULTANTS requires it in order to specify information
                  indicated.
                  <span>
                      16.1.2. For avoidance of any doubt KURTLEY QURESHI CONSULTANTS informs
                      the   Client   that   only   communication   via   the   Platform   and/or
                      communication via official e-mails of KURTLEY QURESHI CONSULTANTS
                      (ending <a rel="noreferrer" href='mailto:office@finstee.com' target='_blank'>office@finstee.com</a>) to the email indicated by the Client in the
                      process of on-boarding or later as the official e-mail address of the Client,
                      and/or communication via official telephones of KURTLEY QURESHI
                      CONSULTANTS to the telephone number indicated by the Client in the
                      process of on-boarding or later as the official telephone number of the
                      Client   shall   entail   legal   consequences   for   KURTLEY   QURESHI
                      CONSULTANTS.<br/>
                      16.1.3. If the Client in breach of clause 16.1.2 chooses any other way of
                      communication, it shall be at the sole risk of the Client, and the Client
                      understands that any arrangements   between the Client and KURTLEY
                      QURESHI CONSULTANTS based on the communication other than the
                      means of communication mentioned in clause 16.1.2 shall not be legally
                      binding upon KURTLEY QURESHI CONSULTANTS.
                  </span>
                  16.2. Where legislation requires KURTLEY QURESHI CONSULTANTS to provide
                  information to the Client on a durable medium, KURTLEY QURESHI CONSULTANTS
                  will either send the Client an e-mail (with or without attachment) or send the Client
                  a notification pointing the Client to information on the Platform in a way that
                  enables the Client to retain the information in print format or other format that can
                  be retained by the Client permanently for future reference. The Client is required to
                  keep copies of all communications KURTLEY QURESHI CONSULTANTS sends or
                  makes available to the Client.<br/>
                  16.3. The Client can request a copy of the Terms and Conditions or any other
                  contractual document relevant to the Client by contacting Customer Service.<br/>
                  16.4. KURTLEY QURESHI CONSULTANTS will communicate to the Client in English
                  and will always accept communications made to KURTLEY QURESHI CONSULTANTS
                  in English.<br/>
                  16.5. Any communication or notice sent by post will be deemed received within
                  seven (7) days of posting for international post.<br/>
                  16.6. Client Account is personal to the Client and the Client may not assign any
                  rights under the Terms and Conditions to any third party.<br/>
                  16.7. The Terms and Conditions are governed by the laws of Canada. The Parties
                  agree to submit to the non-exclusive jurisdiction of the Canada courts.<br/>
                  16.8. If any part of the Terms and Conditions is found by a court of competent
                  jurisdiction to be invalid, unlawful or unenforceable then such part shall be severed
                  from the remainder of the Terms and Conditions, which shall continue to be valid
                  and enforceable fully permitted by law.
              </p>
          </div>
      </Page>
  )
}