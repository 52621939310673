import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {handleGoToLogin, handleGoToRegistration} from "../utils/navigation-utils";
import logo from '../assets/logo.svg';
import hamburger from '../assets/hamburger.svg';
import close from '../assets/close.svg';

export const Header = () => {
    const navigate = useNavigate();
    const [isMenuOpened, setMenuOpened] = useState(false);
    return (
        <header className={isMenuOpened ? 'opened' : ''}>
            <div className="container">
                <div className="header-inner">
                    <div className="logo-wrapper">
                        <img className='logo' src={logo} alt="logo" onClick={() => navigate('/')}/>
                        <div className="additional-buttons">
                            <button className="white-stroke" onClick={handleGoToLogin}>Login</button>
                            <img className='hamburger' src={isMenuOpened ? close : hamburger} alt="hamburger" onClick={() => setMenuOpened(!isMenuOpened)}/>
                        </div>
                    </div>
                    <nav>
                        <Link to={'/'}>Home</Link>
                        <Link to={'/about-us'}>About us</Link>
                        <Link to={'/our-services'}>Our Services</Link>
                        <Link to={'/contact-us'}>Contact us</Link>
                    </nav>
                    <div className='buttons-wrapper row'>
                        <button className="color" onClick={handleGoToRegistration}>Get Account</button>
                        <button className="white-stroke" onClick={handleGoToLogin}>Login</button>
                    </div>
                </div>
            </div>
        </header>
    )
}