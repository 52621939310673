import {useNavigate} from "react-router-dom";
import {Page} from "../components/page";
import snowCristalBg from "../assets/snow-cristal-bg-small.svg"

export const Soon = () => {
    const navigate = useNavigate();

    return (
        <Page>
            <div className="soon">
                <img src={snowCristalBg} alt="snow-cristall"/>
                <h1>Oops</h1>
                <p>Service is under development</p>
                <button className='white-stroke' onClick={() => navigate('/contact-us')}>Contact us</button>
            </div>
        </Page>
    )
}